import React, {useCallback, useContext, useEffect, useState} from "react";
import APIContext from "../../hooks/API";
import {LoginContext} from "../Login";
import CompanySelect from "./CompanySelect";
import StatusFilter from "./StatusFilter";
import Table, {getField} from "./Table";
import DateSelect from "./DateSelect";
import {Container, ContainerHeader} from "../Container";
import {T} from "../traks";
import {useNavigate, useSearchParams} from "react-router-dom";

const columnSort = (data, column, reverse = false) => {
	let d;
	if (column.endsWith("Ts")) {
		d = data.sort(
			(a, b) =>
				new Date(getField(b, column)).getTime() -
				new Date(getField(a, column)).getTime()
		);
	} else {
		d = data.sort((a, b) => {
			let [_a, _b] = [getField(a, column), getField(b, column)];
			return isNaN(_a) ? _a.localeCompare(_b) : _a - _b;
		});
	}
	if (reverse) return d.reverse();
	return d;
};

const Orders = () => {
	const [loading, setLoading] = useState(false);
	const [sortBy, setSortBy] = useState("createdTs");
	const [reverseSort, setReverseSort] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const {get, setCompanyId, companyId} = useContext(APIContext);
	const {user} = useContext(LoginContext);
	const [data, setData] = useState([]);
	const [orderStatus, setOrderStatus] = useState("All");
	const [fromDate, setFromDate] = useState(
		new Date(Date.now() - 1209600000).toISOString().split("T")[0]
	);
	const [toDate, setToDate] = useState(
		new Date(Date.now()).toISOString().split("T")[0]
	);
	const [hasMore, setHasmore] = useState(false);
	const [page, setPage] = useState(0);
	const [filterComponents, setFilterComponents] = useState([]);
	const [filterString, setFilterString] = useState("");
	const [columns, setColumns] = useState([
		"createdTs",
		"invoiceId",
		"orderStatus",
		"details.lines[0].description",
		"details.lines[0].text",
		"details.shippingaddress.email",
		"authorizedAmount",
		"capturedAmount",
		"refundedAmount",
	]);

	const sortedData = useCallback(
		() => columnSort(data, sortBy, reverseSort),
		[data, sortBy, reverseSort]
	);

	//parse searchparams into filter components (only once)
	useEffect(() => {
		if (searchParams.has("page")) setPage(parseInt(searchParams.get("page")));
		if (searchParams.has("orderStatus"))
			setOrderStatus(searchParams.get("orderStatus"));
		else setOrderStatus("All");
		if (searchParams.has("fromDate") && searchParams.has("toDate")) {
			setFromDate(searchParams.get("fromDate"));
			setToDate(searchParams.get("toDate"));
		} else {
			setFromDate(
				new Date(Date.now() - 1209600000).toISOString().split("T")[0]
			);
			setToDate(new Date(Date.now()).toISOString().split("T")[0]);
		}
		if (searchParams.has("filter")) {
			let filter = searchParams.get("filter");
			let components = filter
				.split(",")
				.map((f) => f.split("|"))
				.map(([key, comparator, value]) => ({key, comparator, value}));
			setFilterComponents(components);
		}
	}, []);

	const setFilterInQueryParams = useCallback(() => {
		console.log("setFilterInQueryParams");
		if (!fromDate || !toDate) return;
		let filter = filterComponents
			.map(({key, value, comparator}) => `${key}|${comparator}|${value}`)
			.join(",");
		setSearchParams((p) => {
			if (filter) p.set("filter", filter);
			else p.delete("filter");
			p.set("page", page);
			p.set("fromDate", fromDate);
			p.set("toDate", toDate);
			if (orderStatus !== "All") p.set("orderStatus", orderStatus);
			else p.delete("orderStatus");
			return p;
		});
	}, [fromDate, toDate, orderStatus, page, filterComponents, setSearchParams]);

	useEffect(() => {
		console.log("setFilterFromStates and update query params");
		let filter = filterComponents
			.map(({key, value, comparator}) => `${key}|${comparator}|${value}`)
			.join(",");
		if (orderStatus !== "All") {
			if (filter) filter += ",";
			filter += `orderStatus|eq|${orderStatus}`;
		}
		if (filter) filter += ",";

		filter += `createdTs|between|${fromDate}_${toDate}`;
		setFilterString(filter);
		setFilterInQueryParams();
	}, [filterComponents, orderStatus, fromDate, toDate, setFilterInQueryParams]);

	useEffect(() => {
		if (!user || !user?.affiliates?.length || companyId) return;
		setCompanyId(user.affiliates.length ? user.affiliates[0] : "");
	}, [setCompanyId, user, user?.affiliates, companyId]);

	useEffect(() => {
		if (companyId && filterString) {
			setLoading(true);
			let args = {filter: filterString, page, columns};
			get({
				path: "/order",
				args,
			}).then((r) => {
				if (!r) return;
				setLoading(false);
				setHasmore(r.hasMore);
				if (page === 0) {
					setData(r.orders);
				} else {
					setData((p) => [...p, ...r.orders]);
				}
			});
		}
	}, [companyId, page, get, filterString]);

	if (!user)
		return (
			<div>
				<T>Loading</T>
			</div>
		);
	return (
		<div className="container-fluid">
			<div className="m-3 my-4 col-2">
				<CompanySelect
					affiliates={user?.affiliates?.length ? user.affiliates : []}
				/>
			</div>
			<Container nobreak>
				<ContainerHeader>
					<T>Query by order id</T>
				</ContainerHeader>
				<div className="my-2 mx-3">
					<div className="d-flex flex-column flex-xxl-row">
						<FindOrderId />
					</div>
				</div>
			</Container>
			<Container nobreak>
				<ContainerHeader>
					<T>Filter search</T>
				</ContainerHeader>
				<div className="my-2 mx-3">
					<div className="d-flex flex-column flex-xxl-row">
						<DateSelect
							fromDate={fromDate}
							setFromDate={setFromDate}
							toDate={toDate}
							setToDate={setToDate}
						/>
						<div className="d-flex flex-column flex-xl-row justify-content-evenly flex-fill">
							<StatusFilter
								orderStatus={orderStatus}
								setOrderStatus={setOrderStatus}
							/>
						</div>
					</div>

					<div className="m-2 d-flex flex-row gap-2">
						{filterComponents.map(({key, comparator, value}, idx) => (
							<ShowFilter
								key={`${key}-${idx}`}
								fKey={key}
								fComparator={comparator}
								fValue={value}
								setFilterComponents={setFilterComponents}
							/>
						))}
					</div>
					<div className="d-flex flex-column flex-xxl-row">
						<div className="d-flex flex-column flex-xl-row justify-content-evenly flex-fill">
							<CustomFilterComponent
								setFilterComponents={setFilterComponents}
							/>
						</div>
					</div>
					<div>
						<button
							type="button"
							disabled={true}
							className="btn btn-warning m-2"
							style={{
								width: "140px",
								height: "50px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								setPage(0);
								setData([]);
								// loadData();
							}}
						>
							{loading ? (
								<div className="spinner-border text-dark" role="status" />
							) : (
								<T>Search</T>
							)}
						</button>
					</div>
				</div>
			</Container>
			<Table
				data={sortedData()}
				sortBy={sortBy}
				setSortBy={setSortBy}
				reverseSort={reverseSort}
				setReverseSort={setReverseSort}
				columns={columns}
				setColumns={setColumns}
				filter={filterString}
			/>
			{hasMore && (
				<button
					type="button"
					className="btn btn-primary m-4"
					style={{
						width: "140px",
						height: "50px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => {
						setPage((p) => p + 1);
					}}
				>
					{loading ? (
						<div className="spinner-border text-light" role="status" />
					) : (
						<T>More</T>
					)}
				</button>
			)}
		</div>
	);
};

const comparator2string = {
	eq: "=",
	contains: "contains",
	lt: "<",
	gt: ">",
	lte: "<=",
	gte: ">=",
};

const ShowFilter = ({fKey, fComparator, fValue, setFilterComponents}) => {
	return (
		<div className="card" style={{width: "18rem"}}>
			<div className="card-body d-flex flex-row justify-content-between">
				<div className="card-title">
					{fKey} {comparator2string[fComparator]} {fValue}
				</div>
				<button
					className="close"
					onClick={() =>
						setFilterComponents((p) =>
							p.filter(
								({key, comparator, value}) =>
									!(
										key === fKey &&
										comparator === fComparator &&
										value === fValue
									)
							)
						)
					}
				>
					&times;
				</button>
			</div>
		</div>
	);
};

const FindOrderId = () => {
	const [orderId, setOrderId] = useState("");
	const [order, setOrder] = useState();
	const navigate = useNavigate();
	const {get, companyId} = useContext(APIContext);

	const search = useCallback(() => {
		get({path: `/order/${orderId}`}).then((e) => {
			console.log(e.orderId);
			if (e?.orderId) setOrder(e);
		});
	}, [get, orderId]);

	const navigateToOrder = useCallback(() => {
		navigate(`/orders/${order.companyId}/${order.orderId}`, {});
	}, [navigate, order?.companyId, order?.orderId]);

	useEffect(() => {
		if (orderId.length === 20) {
			search();
		} else {
			setOrder();
		}
	}, [orderId, search]);

	return (
		<div className="input-group m-2">
			<span className="input-group-text col-2">
				Find order in {companyId} by Id
			</span>
			<input
				placeholder="Order Id"
				className={`form-control`}
				onChange={(e) => setOrderId(e.target.value)}
				value={orderId}
			/>
			<div className="input-group-append">
				<button
					className="btn btn-outline-secondary"
					type="button"
					onClick={navigateToOrder}
					disabled={!order}
				>
					{order ? "View order" : "No match found"}
				</button>
			</div>
		</div>
	);
};

const CustomFilterComponent = ({setFilterComponents}) => {
	const [key, setKey] = useState("");
	const [comparator, setComparator] = useState("none");
	const [value, setValue] = useState("");

	const addFilter = () => {
		setFilterComponents((p) => [...p, {key, comparator, value}]);
		setKey("");
		setComparator("none");
		setValue("");
	};

	return (
		<div className="input-group m-2">
			<span className="input-group-text col-2">Filter</span>
			<input
				placeholder="Column name (e.g. details.shippingaddress.email)"
				className={`form-control`}
				onChange={(e) => setKey(e.target.value)}
				value={key}
			/>
			<select
				className="form-select"
				onChange={(e) => {
					setComparator(e.target.value);
				}}
				value={comparator}
			>
				<option value="none">Select comparator</option>
				<option value="contains">Contains</option>
				<option value="gt">Greater than</option>
				<option value="lt">Less than</option>
				<option value="gte">Greater than or equal</option>
				<option value="lte">Less than or equal</option>
			</select>
			<input
				placeholder="Value"
				className={`form-control`}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
			<div className="input-group-append">
				<button
					className="btn btn-outline-secondary"
					type="button"
					onClick={addFilter}
					disabled={!(key && value && comparator !== "none")}
				>
					Add Filter
				</button>
			</div>
		</div>
	);
};
export default Orders;
